<template>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col cols="5">
        <b-button
          variant="gradient-primary"
          class="mr-1"
          @click="showModal('add-driver')"
        >
          <span class=""> ADD DRIVER </span>
          <feather-icon
            icon="PlusCircleIcon"
            size="14"
            class="text-white ml-1"
          />
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <MyTable :columns="columns" :rows="driversList" :is-loading="isLoading">
        <template v-slot="{ props }">
          <div v-if="props.column.field === 'id'">
            <b-button
              variant="gradient-primary"
              block
              @click="goToDriverDetails(props.row.id)"
            >
              {{ props.row.id ? props.row.id : '' }}
            </b-button>
          </div>
          <div v-if="props.column.field === 'name'">
            <b-avatar
              :src="
                props.row.driver && props.row.driver.driver_image
                  ? srcUrl + props.row.driver.driver_image
                  : ''
              "
              class="mr-2"
            />
            {{ props.row.name ? props.row.name : '' }}
          </div>
          <span v-if="props.column.field == 'branch'">
            <b-badge variant="primary" class="w-100 badge-padding">
              {{ props.row.branch ? props.row.branch.area : '' }}
            </b-badge>
          </span>

          <span v-if="props.column.field == 'email'">
            {{ props.row.email ? props.row.email : '' }}
          </span>

          <span v-if="props.column.field == 'phone'">
            {{ props.row.phone ? props.row.phone : '' }}
          </span>

          <span v-if="props.column.field == 'status'">
            <b-button
              v-if="props.row.status === 1"
              block
              variant="gradient-success"
            >
              Active
            </b-button>
            <b-button v-else block variant="gradient-danger">
              Inactive
            </b-button>
          </span>

          <div v-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="props.row.status.value !== 'process'"
                @click="showModal('edit-driver', props.row)"
              >
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit Driver</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="props.row.status.value !== 'process'"
                @click="handleDelete(props.row)"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete Driver</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </MyTable>
    </b-card>

    <b-modal
      ref="modal"
      :title="modal.title"
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <AddDriver
        v-if="modal.type === 'add-driver'"
        @driver-added="handlePostRequest"
      />
      <EditDriver
        v-if="modal.type === 'edit-driver'"
        :data="modal.data"
        @driver-edited="handlePostRequest"
      />
    </b-modal>
  </b-container>
</template>

<script>
import MyTable from '@/views/components/MyTable.vue';
import { requestError } from '@/common/SwalOptions';
import AddDriver from '@/views/pages/inside/Driver/AddDriver.vue';
import EditDriver from '@/views/pages/inside/Driver/EditDriver.vue';

export default {
  components: {
    MyTable,
    AddDriver,
    EditDriver,
  },

  data() {
    return {
      branch: [],
      rows: [],
      isLoading: false,
      srcUrl: `${this.$s3URL}/minidc/driver_photo/`,
      columns: [
        {
          label: 'Driver ID',
          field: 'id',
        },
        {
          label: 'Driver Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Phone Number',
          field: 'phone',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      modal: {
        title: '',
        type: '',
        data: [],
      },
    };
  },
  computed: {
    driversList() {
      return [...this.rows].reverse();
    },
  },
  created() {
    this.getDrivers();
  },
  methods: {
    showModal(type, data = []) {
      const title = type.replace('-', ' ').toUpperCase();
      this.modal = {
        title,
        type,
        data,
      };
      this.$refs.modal.show();
    },

    async getDrivers(refresh = false) {
      if (refresh === true) {
        this.rows = [];
      }
      this.isLoading = true;
      const response = await this.$http.post('get_driver_by_branch', {});
      if (response.data.status) {
        this.rows = response.data.drivers;
      } else {
        this.$swal(requestError);
      }
      this.isLoading = false;
    },

    handlePostRequest() {
      this.$refs.modal.hide();
      this.getDrivers(true);
    },

    handleDelete(row) {
      this.$swal({
        title: 'Are you sure ?',
        text: `You are about to delete ${row.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger mr-2',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.$http.delete(`driver/${row.id}`);
          if (response.data.status) {
            this.getDrivers();
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          } else {
            this.$swal(requestError);
          }
        }
      });
    },

    goToDriverDetails(id) {
      this.$router.push({
        name: 'driver-details',
        params: {
          id,
        },
      });
    },
  },
};
</script>
